*,
::after,
::before {
  box-sizing: border-box;
}

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;900&display=swap');

html {
  font-size: 100%;
  -webkit-font-smoothing: 'antialiased';
  height: 100%;
  width: 100%;
} /*16px*/


body {
  font-family: 'Raleway', Sans-Serif;
  font-weight: 400;
  padding:0;
  margin: 0;
  color: black;
  height: '100%';
  width: '100%';
}


#root {
  height: 100%;
  width: 100%;
}

